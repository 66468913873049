import { RouteComponentProps, withRouter } from "react-router";
import * as React from "react";
import { observer } from "mobx-react";
import { DataStore } from "ts/stores/data-store";
import { DetailsPanel } from "ts/components/details/DetailsPanel";
import { DetailsBlock } from "ts/components/details/DetailsBlock";
import { DetailValueField } from "ts/components/details/DetailValueField";
import { Date, NumberWithPostfix } from "ts/components/shared/display";
import { DetailsAction } from "ts/components/details/DetailsAction";
import { TabPanel } from "ts/components/shared/TabPanel";
import { Tab } from "ts/components/shared/Tab";
import { Button } from "ts/components/shared/Button";
import { Audit } from "ts/components/shared/audit/Audit";
import { OrderDetailsModel } from "ts/models/shared/OrderDetailsModel";
import { paintTypeFromEnum, corrosivityFromEnum } from "ts/core/enumHelper";
import { showRalAndGloss } from "ts/core/util";
import DeleteIcon from "svg/trash.svg";
import EditIcon from "svg/edit.svg";
import WorkOrderIcon from "svg/workorder.svg";
import { Dialog } from "../../../core/dialog";
import { default as RootStore } from "../../../stores/root-store";
import { sum } from "lodash-es";
import { Enums } from "../../../stores/lookup-store";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import { AddressView } from "../../../components/shared/AddressView";
import { EntityType, toSerialNumber } from "../../../core/stringHelpers";
import { DocumentList } from "../../../components/shared/DocumentList";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "../../../components/shared/CopyToClipboard";
import { toDateWithHM, toDate } from "../../../core/dateHelpers";
import { Network } from "../../../api/network";
import { TransportUnit } from "../../../models/shared/TransportUnit";
import TransportIcon from "svg/transport.svg";
import "./OrderDetails.tsx.scss";
import { TransportOrderDialog } from "../../../components/shared/TransportOrderDialog";
import { TransportOrderList } from "../../../components/shared/TransportOrderList";
import { ChatPageList } from "../../../components/shared/messages/MessageDialog";

type P = RouteComponentProps<{ id: string }>;

interface OwnState {
  options: Array<BaseLookupModel>;
}


@observer
class OrderDetailsPage extends React.Component<P> {
  orderId: number;
  store: DataStore<OrderDetailsModel>;
  transportUnitTypes: BaseLookupModel[] | undefined;
  state: OwnState = { options: [] };

  constructor(props: P) {
    super(props);

    this.orderId = parseInt(this.props.match.params.id);

    this.store = new DataStore<OrderDetailsModel>(`/api/orders/${this.orderId}/details`, [{
      id: this.orderId,
      type: "OrderEntity"
    }]);

  }

  componentWillUnmount = () => this.store.dispose();

  editOrder = () => {
    const { history } = this.props;
    history.push(`/orders/${this.orderId}/edit`);
  }

  deleteOrder = () => {
    const { history } = this.props;

    const dialog = Dialog.OkCancelDialog(
      "Slette bestilling",
      `Er du sikker på at du vil slette bestilling ${this.orderId} fra ${this.store.data!.CustomerName}?`,
      "Slett",
      async (fnClose) => {

        // attemt to delete the entity.
        const deleteWasSuccessfull = await this.store.delete("orders", this.orderId);

        // Close the dialog no matter what. If an error occured, another dialog will show error message.
        fnClose();

        // If the delete was successfull, navigate to the listing page.
        if (deleteWasSuccessfull === true) {
          history.push(`/orders`);
        }
      }
    );

    RootStore.UIStore.addDialog(dialog);
  }

  async componentDidMount() {
    const options = await RootStore.LookupStore.getEnums(Enums.TransportUnitType, false);
    if (options)
      this.setState({ options });
  }

  createWorkOrder = () => {
    const { history } = this.props;
    history.push(`/workorders/create/${this.store.data!.CustomerId}/${this.orderId}/0`);
  }

  orderTransport = () => {
    const { history } = this.props;
    history.push(`/transport/create/${this.orderId}/0`);
  }

  render() {
    const model = this.store.data;
    if (!model)
      return null;

    const hasAssociatedWorkOrders = model.AssociatedWorkOrders != null && model.AssociatedWorkOrders.length > 0;

    // const showOrderTransportButton = model.RequiresPickup && model.TransportOrderId == null;
    const showAddressInformation = model.RequiresDelivery || model.RequiresPickup;

    const isCustomer = RootStore.UserStore.isCustomer;
    const isAdministrator = RootStore.UserStore.isEmployeeAdministrator;

    const id = toSerialNumber(EntityType.Order, model.Id);

    let transportTab = 0;
    if (hasAssociatedWorkOrders)
      transportTab++;
    if (model.Units != null)
      transportTab++;
    const initialTab = (this.props.location.state as any)?.tab === "transport" ? transportTab : undefined;

    return (<>
      <DetailsPanel>
        <DetailsBlock title="Informasjon">
          <DetailValueField title="Bestillingsnummer">
            <Link to={`/orders/${model.Id}/edit`}>{id}</Link>
            <CopyToClipboard text={id!} />
          </DetailValueField>
          <DetailValueField title="Kunde" wrap={true}><Link to={`/customers/${model.CustomerId}`}>{model.CustomerName}</Link></DetailValueField>
          <DetailValueField title="Opprettet av" wrap={true}><Link to={`/users/${model.CreatedById}`}>{model.CreatedByName}</Link></DetailValueField>
          <DetailValueField title="Fakturareferanse" wrap={true}>{model.InvoiceReference}</DetailValueField>
          <DetailValueField title="Estimert vekt"><NumberWithPostfix number={model.EstimatedWeight} post="kg" /></DetailValueField>
          <DetailValueField title="Antall kolli">{model.UnitCount}</DetailValueField>
          <DetailValueField title="Beskrivelse" wrap long>{model.Description}</DetailValueField>
        </DetailsBlock>
        <DetailsBlock title="Behandling">
          <DetailValueField title="Korrosjonsklasse">{corrosivityFromEnum(model.Corrosivity)}</DetailValueField>
          <DetailValueField title="Lakkering">{paintTypeFromEnum(model.Paint)}</DetailValueField>
          {showRalAndGloss(model.Paint) && <DetailValueField title="Ralkode">{model.RalCode}</DetailValueField>}
          {showRalAndGloss(model.Paint) && model.PaintGloss > 0 && <DetailValueField title="Glans">{model.PaintGloss}</DetailValueField>}
          {model.IsCEMarking && <DetailValueField title="CE Merking">Ønsker CE merking</DetailValueField>}
          <DetailValueField title="Informasjon" wrap long>{model.Information}</DetailValueField>
        </DetailsBlock>
        <DetailsBlock title="Mottak">
          <DetailValueField title="Antatt mottaksdato"><Date date={model.EstimatedDeliveryDate} /></DetailValueField>
          <DetailValueField title="Transport">{model.RequiresPickup ? "Hentes av RåZink" : "Leveres av kunde"}</DetailValueField>
          {model.RequiresPickup && <DetailValueField title="Henting">{model.IsMissingPickupOrder ? <b>Må bestilles</b> : "Er bestillt"}</DetailValueField>}
        </DetailsBlock>
        <DetailsBlock title="Ferdigstillelse">
          <DetailValueField title="Ønsket ferdigstillelse"><Date date={model.RequestedCompletionDate} /></DetailValueField>
          <DetailValueField title="Transport">{model.RequiresDelivery ? "Leveres av RåZink" : "Hentes av kunde"}</DetailValueField>
        </DetailsBlock>
        <DetailsAction>
          <Button text="Rediger" onClick={() => this.editOrder()} icon={EditIcon}></Button>
          {!isCustomer && <Button text="Opprett arbeidsordre" onClick={() => this.createWorkOrder()} icon={WorkOrderIcon}></Button>}
          {isAdministrator && <Button text="Slett" onClick={() => this.deleteOrder()} icon={DeleteIcon}></Button>}
        </DetailsAction>
      </DetailsPanel>
      <TabPanel initialSelectedTab={initialTab}>
        {hasAssociatedWorkOrders && <Tab title="Arbeidsordre" count={model.AssociatedWorkOrders!.length}>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Referanse</th>
                  <th>Motatt</th>
                  <th>Status</th>
                  <th>Leveringstid</th>
                  <th>Vekt</th>
                  <th>Fjern</th>
                </tr>
              </thead>
              <tbody>
                {model.AssociatedWorkOrders!.map(wo => <tr key={wo.Id}>
                  <td>{toSerialNumber(EntityType.WorkOrder, wo.Id)}</td>
                  <td>{wo.InvoiceText}</td>
                  <td>{toDateWithHM(wo.Created)}</td>
                  <td>
                    <Link to={`/workorders/${wo.Id}`}>
                      {wo.Aggregate?.State?.Label}
                    </Link>
                  </td>
                  <td>{toDateWithHM(wo.DeliveryDate)}</td>
                  <td><NumberWithPostfix post="kg" number={wo.Aggregate?.OutgoingWeight == 0 ? wo.Aggregate.IncomingWeight : wo.Aggregate?.OutgoingWeight} empty="" /></td>
                  <td><a href="#" onClick={e => this.removeWorkOrderFromOrder(e, wo.Id)}>Fjern</a></td>
                </tr>)}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5} />
                  <td><NumberWithPostfix
                    number={sum(model.AssociatedWorkOrders!.map(u => u.Aggregate?.OutgoingWeight! > 0 ? u.Aggregate?.OutgoingWeight : u.Aggregate?.IncomingWeight))}
                    empty=""
                    post="kg" />
                  </td>
                  <td />
                </tr>
              </tfoot>
            </table>
          </div>
        </Tab>}
        {model.Units && <Tab title={`Kollidetaljer (${model.Units.length})`}>
          <UnitDescription units={model.Units} options={this.state.options} />
        </Tab>}
        {showAddressInformation && <Tab title="Transport">
          <div className="order-transport-container">
            <div className="order-transport-details">
              <div className="details-panel-item">
                <AddressView address={model.PickupAddress} title="Henteadresse" />
                <div className="kolliview">
                  <h3>Kolli-informasjon</h3>
                  <UnitDescription units={model.Units} options={this.state.options} />
                </div>
                <AddressView address={model.DeliveryAddress} title="Leveringsadresse (etter produksjon)" />
              </div>
              {!isCustomer && model.RequiresPickup && <div className="details-panel-item details-action-list details-right">
                <Button text="Registrer henting" onClick={() => this.registerTransportOrder()} icon={TransportIcon}></Button>
              </div>}
            </div>
            <div className="order-transport-list">
              <TransportOrderList orderId={model.Id} isCustomer={isCustomer} />
            </div>
          </div>
        </Tab>}
        {model.Documents && model.Documents.length > 0 && <Tab title="Dokumenter" count={model.Documents.length}>
          <DocumentList files={model.Documents} />
        </Tab>}
        {!isCustomer && <Tab title="Notisblokk" count={model.NoteMessageCount}>
          <ChatPageList type="OrderEntity" id={model.Id} />
        </Tab>}
        {!isCustomer && <Tab title="Historikk">
          <Audit type="Logi.Proflyt.Data.Entities.OrderEntity" id={model.Id} isWorkorderRelated={false} />
        </Tab>}
      </TabPanel>
    </>);
  }

  removeWorkOrderFromOrder(ev: React.MouseEvent, id: number): void {
    ev.preventDefault();

    var dialog = Dialog.OkCancelDialog(
      "Fjern arbeidsordre fra bestilling",
      `Er du sikker på at du vil fjerne koblingen mellom arbeidsordre ${toSerialNumber(EntityType.WorkOrder, id)} og denne bestillingen? Merk at verdier som allerede er satt på arbeidsordren ikke vil fjernes.`,
      "Fjern",
      async close => {
        const network = new Network();
        await network.get(`/api/orders/removeWorkOrder/${id}`)
        close();
      }
    )

    Dialog.Show(dialog);
  }

  registerTransportOrder() {

    var dialog = new Dialog<any>();
    dialog.title = "Registrer transportbestilling";
    dialog.closable = true;
    dialog.content = <TransportOrderDialog orderId={this.store.data?.Id} preselectedDate={this.store.data?.EstimatedDeliveryDate} />
    dialog.buttons = [];
    Dialog.Show(dialog);
  }
}


const a = withRouter(OrderDetailsPage);
export {
  a as OrderDetailsPage
}

const UnitDescription: React.FC<{
  units?: Array<TransportUnit>,
  options: Array<BaseLookupModel>
}> = ({ units, options }) => {
  if (units == null || units.length == 0)
    return null;

  return <div className="table-container">
    <table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Vekt</th>
          <th>Størrelse</th>
        </tr>
      </thead>
      <tbody>
        {units.map((u, i) => <tr key={i}>
          <td>{options && options.find(l => l.Id == u.Type)?.Name}</td>
          <td className="no-wrap">{u.Weight} kg</td>
          <td className="no-wrap">{u.HasSize === true && `${u.Length} cm x ${u.Width} cm x ${u.Height} cm`}</td>
        </tr>)}
      </tbody>
      <tfoot>
        <tr>
          <td />
          <td className="no-wrap">{sum(units.map(u => u.Weight))} kg</td>
          <td />
        </tr>
      </tfoot>
    </table>
  </div>
}