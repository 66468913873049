import * as React from "react";
import { IRootStore } from "../../stores/root-store";
import "./header.scss";
import { Hamburger } from "./Hamburger";
import { HeaderUserName } from "./HeaderUserName";
import { default as RootStore } from "../../stores/root-store";
import { Pending } from "../shared/Pending";
import { observer } from "mobx-react";
import { SearchBox } from "./SearchBox";
import { NavLink } from "react-router-dom";

@observer
export class Header extends React.Component<Partial<IRootStore>> {
  render() {

    const isCustomer = RootStore.UserStore.isCustomer;
    const environment = window.flytSettings.environment;

    return (
      <header className={`environment-${environment.toLowerCase()}`}>
        <div className="flex-center-items">
          <Hamburger />
          <NavLink className="app-title" to="/">RåLink{(environment === "Production" ? "" : ` - ${environment}`)}</NavLink>
          {!isCustomer && <a href="http://luft.corp.razink.no/" target="_blank" className="site-link">RåLuft</a>}
          {!isCustomer && <a href="http://gryte.corp.razink.no/" target="_blank" className="site-link">RåDupp</a>}
          {!isCustomer && <a href="http://crm.corp.razink.no/" target="_blank" className="site-link">CRM</a>}
        </div>
        <div className="flex-center">
          {RootStore.UIStore.hasActiveNetworkCalls && <Pending />}
          {!isCustomer && <SearchBox />}
          <HeaderUserName />
        </div>
      </header>
    )
  }
}