import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react";
import { UserDetailsModel } from "../../../models/shared/UserDetailsModel";
import { DataStore } from "../../../stores/data-store";
import { Dialog } from "../../../core/dialog";
import { default as RootStore } from "../../../stores/root-store";
import { DetailsPanel } from "../../../components/details/DetailsPanel";
import { DetailsBlock } from "../../../components/details/DetailsBlock";
import { DetailValueField } from "../../../components/details/DetailValueField";
import { TabPanel } from "../../../components/shared/TabPanel";
import { Tab } from "../../../components/shared/Tab";
import { Audit } from "../../../components/shared/audit/Audit";
import { DetailsAction } from "../../../components/details/DetailsAction";
import { Button } from "../../../components/shared/Button";
import EditIcon from "svg/edit.svg";
import DeleteIcon from "svg/trash.svg";
import LockIcon from "svg/lock.svg";
import UserWithLogonIcon from "svg/user-with-login.svg";
import { toDateWithHM, since } from "../../../core/dateHelpers";
import { Network } from "../../../api/network";
import { MessageList } from "../../../components/shared/communications/MessageList";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { Link } from "react-router-dom";
import { Enums } from "../../../stores/lookup-store";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";
import { PhoneLink } from "../../../components/shared/display";
import { infoToast } from "../../../components/shared/Toasts";

type P = RouteComponentProps<{ id: string }>;
type S = { hasResetPassword: boolean; hasPendingNetworkCall: boolean };

@observer
class UserDetailsPage extends React.Component<P, S> {
  store: DataStore<UserDetailsModel>;
  state = { hasResetPassword: false, hasPendingNetworkCall: false };
  notificationTypes: BaseLookupModel[] | undefined;


  constructor(props: P) {
    super(props);

    const id = parseInt(this.props.match.params.id);

    this.store = new DataStore<UserDetailsModel>(`/api/users/${id}/details`, [{
      id: id,
      type: "UserEntity"
    }]);

    RootStore.LookupStore.getEnums(Enums.NotificationType).then(r => this.notificationTypes = r);
  }

  componentWillUnmount = () => this.store.dispose();

  edit = () => {
    const { history } = this.props;
    const id = this.store.data!.Id;
    history.push(`/users/${id}/edit`);
  }

  resetPassword = async () => {
    const network = new Network();
    const id = this.store.data!.Id;
    this.setState({ hasPendingNetworkCall: true });

    const success = await network.getSimple<boolean>(`/api/authentication/resetPassword/${id}`);
    if (success === true) {
      infoToast(`Nytt passord er sendt via epost til ${this.store.data!.Name}.`);
      this.setState({ hasPendingNetworkCall: false, hasResetPassword: true });
    } else {
      this.setState({ hasPendingNetworkCall: false });
    }
  }

  activateLogon = () => {

    const { Id, Name, CustomerName } = this.store.data!;
    const dialog = Dialog.OkCancelDialog(
      "Aktiver pålogging",
      `Er du sikker på at du vil gi ${Name} påloggingsrettighter for ${CustomerName}?`,
      "Aktiver",
      async (fnClose) => {
        // attemt to delete the entity.
        await new Network().get(`/api/users/convertToLoginUser/${Id}`)

        // Close the dialog no matter what. If an error occured, another dialog will show error message.
        fnClose();
      }
    );

    RootStore.UIStore.addDialog(dialog);
  }

  delete = () => {
    const { history } = this.props;
    const { Id, Name } = this.store.data!;

    const dialog = Dialog.OkCancelDialog(
      "Slette bruker",
      `Er du sikker på at du vil slette brukeren ${Name} med bruker-id ${Id}?`,
      "Slett",
      async (fnClose) => {

        // attemt to delete the entity.
        const deleteWasSuccessfull = await this.store.delete("users", Id);

        // Close the dialog no matter what. If an error occured, another dialog will show error message.
        fnClose();

        // If the delete was successfull, navigate to the listing page.
        if (deleteWasSuccessfull === true) {
          history.push(`/users`);
        }
      }
    );

    RootStore.UIStore.addDialog(dialog);
  }

  render() {

    const model = this.store.data;
    if (!model)
      return null;

    const isEmployee = model.CustomerId == null || model.CustomerId === 0;
    const isCustomer = RootStore.UserStore.isCustomer;
    const isAdministrator = RootStore.UserStore.isEmployeeAdministrator;

    return (<>
      <DetailsPanel>
        <DetailsBlock title="Oversikt">
          <DetailValueField title="Brukerid">{toSerialNumber(EntityType.User, model.Id)}</DetailValueField>
          <DetailValueField title="Navn">{model.Name}</DetailValueField>
          {!isEmployee && <DetailValueField title="Tilknyttet kunde"><Link to={`/customers/${model.CustomerId}`}>{model.CustomerName}</Link></DetailValueField>}
          <DetailValueField title="E-post"><a href={`mailto:${model.Email}`}>{model.Email}</a></DetailValueField>
          <DetailValueField title="Telefonnummer"><PhoneLink number={model.PhoneNumber} /></DetailValueField>
          <DetailValueField title="Tittel">{model.Title}</DetailValueField>
          <DetailValueField title="Sist pålogget"><span title={since(model.LastOn)}>{toDateWithHM(model.LastOn)}</span></DetailValueField>
        </DetailsBlock>
        {!isCustomer && <DetailsBlock title="Informasjon">
          <DetailValueField title="Informasjon" wrap={true} long>{model.Description}</DetailValueField>
        </DetailsBlock>}
        <DetailsAction>
          {(!isCustomer || model.Id == RootStore.UserStore.userId) && <Button text="Rediger" onClick={() => this.edit()} icon={EditIcon}></Button>}
          {isAdministrator && <Button text="Slett" onClick={() => this.delete()} icon={DeleteIcon}></Button>}
          {isAdministrator && model.CanLogOn && <Button
            text="Resett passord"
            onClick={() => this.resetPassword()}
            disabled={this.state.hasPendingNetworkCall || this.state.hasResetPassword}
            icon={LockIcon}></Button>}
          {isAdministrator && !model.CanLogOn && model.Email != null &&
            <Button text="Aktiver pålogging" onClick={() => this.activateLogon()} disabled={this.state.hasPendingNetworkCall} icon={UserWithLogonIcon} />}
        </DetailsAction>
      </DetailsPanel>
      <TabPanel>
        {!isEmployee &&
          <Tab title="Varsler">
            <h4>Registrerte varsler og kommunikasjonsvalg</h4>
            <div className="table-container">
              <table className="no-side-padding normal-width">
                <tbody>
                  {model.Notifications && this.notificationTypes && model.Notifications.map((n, i) => {
                    let info = "";

                    if (n.ViaEmail == false && n.ViaText == false)
                      info = "Ingen kommunikasjon"
                    else if (n.ViaEmail && n.ViaText)
                      info = "Via epost og tekstmelding";
                    else if (n.ViaText)
                      info = "Via tekstmelding"
                    else info = "Via e-post";

                    if (n.OnlyWhenContact)
                      info += ", men bare når kontakt";

                    const name = this.notificationTypes!.find(i => i.Id == n.Type)?.Name;

                    return <tr key={n.Type}><td>{name}</td><td>{info}</td></tr>
                  })}
                </tbody>
              </table>
            </div>
          </Tab>
        }
        {!isCustomer && <Tab title="Siste aktivitet">
          <Audit userId={model.Id} />
          &nbsp;
        </Tab>}
        {!isCustomer && <Tab title="Historikk">
          <Audit type="Logi.Proflyt.Data.Entities.CustomerEntity" id={model.Id} isWorkorderRelated={false} />
        </Tab>}
        {!isCustomer && <Tab title="Meldinger">
          <MessageList userId={model.Id} />
        </Tab>}
      </TabPanel>
    </>);
  }
}

const tmp = withRouter(UserDetailsPage);
export {
  tmp as UserDetailsPage
}
