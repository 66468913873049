import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { SelectCustomerType } from "./SelectCustomerType";
import { OrganizationLookup } from "./OrganizationLookup";
import { CustomerEditForm, CompanyEditInformation } from "./CustomerEditForm";

// /customers/32/edit/
// /customers/create

type P = RouteComponentProps<{id?: string}>;

interface State {
  type?: "private" | "company";
  companyInformation?: CompanyEditInformation
}

class CustomerEdit extends React.Component<P, State> {

  state = { type: undefined, companyInformation: undefined };

  render() {
    const isEdit = this.props.location.pathname.toLowerCase().indexOf("/edit") >= 0;
    
    if (!isEdit) {
      if (this.state.type == undefined)
        return <SelectCustomerType onSelect={customerType => this.setState({ type: customerType })} />
      else if (this.state.type === "company" && this.state.companyInformation === undefined)
        return <OrganizationLookup onSelect={(companyInformation) => this.setState({ companyInformation })} />
      else
        return <CustomerEditForm {...this.state} />
    } else {
      return <CustomerEditForm {...this.state} id={this.props.match.params.id == null ? undefined : parseInt(this.props.match.params.id)} />
    }
  }
}

const a = withRouter(CustomerEdit);
export {
  a as CustomerEdit
}