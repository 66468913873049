import * as React from "react";
import CustomerIcon from "svg/customer.svg";
import EmployeeIcon from "svg/employee.svg";
import CaretIcon from "svg/caret.svg";
import { IRootStore } from "../../stores/root-store";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import "./HeaderUserName.tsx.scss";
import { AuthenticationHelper } from "ts/api/authentication";

interface State {
  isOpen: boolean;
}

@inject(stores => ({ ...(stores as object) }))
@observer
export class HeaderUserName extends React.Component<Partial<IRootStore>, State> {

  state = { isOpen: false };

  toggleMenu = () => {
    this.setState(s => ({ isOpen: !s.isOpen }));
  }

  signOut = async () => {
    await new AuthenticationHelper().logout();
    this.props.RouterStore?.push("/");
  }

  changePassword = () => {
    const currentUserId = this.props.UserStore?.userId;
    this.props.RouterStore?.push(`/users/${currentUserId}/changePassword`);
  }

  render() {
    const { requiresAuthentication, name, isCustomer, isAdministrator } = this.props.UserStore!

    if (requiresAuthentication)
      return null;

    return (
      <div
        aria-haspopup="listbox"
        aria-expanded={this.state.isOpen}
        className="header--userInfo"
        onClick={e => this.toggleMenu()}>
        {isCustomer ? <CustomerIcon title="Kunde" className="icon" /> : <EmployeeIcon title={isAdministrator ? "Administrator" : "Ansatt"} className="icon" />}
        <span>{name || "Ukjent navn"}</span>
        <CaretIcon className="icon caret" />
        <ul
          tabIndex={-1}
          role="listbox"
          className="context-menu">
          <li className="context-item" role="option" tabIndex={0}><Link to="/users/changePassword">Bytt passord</Link></li>
          <li className="context-item" role="option" tabIndex={0}><span onClick={e => this.signOut()}>Logg ut</span></li>
        </ul>
      </div >
    )
  }
}