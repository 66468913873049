import * as React from "react";
import PersonIcon from "svg/person.svg";
import CompanyIcon from "svg/company.svg";
import "./selectCustomerType.scss";

interface Props {
  onSelect: (type: "private" | "company") => void;
}

export class SelectCustomerType extends React.PureComponent<Props> {
  render() {
    return <div className="customer-type-selector panel panel-padding">
      <h3>Velg kundetype</h3>
      <div className="customer-type-selector-items">
        <button type="button" onClick={e => this.props.onSelect("company")} className="customer-type-selector-item" autoFocus>
          <CompanyIcon />
          <h4>Bedriftskunde</h4>
        </button>
        <button type="button" onClick={e => this.props.onSelect("private")} className="customer-type-selector-item">
          <PersonIcon />
          <h4>Privatperson</h4>
        </button>
      </div>
    </div>
  }
}