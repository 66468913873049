import { FormStore, IValidationRules } from "../../../stores/form-store";
import * as React from "react";
import { CustomerEditModel } from "../../../models/shared/CustomerEditModel";
import { Form } from "../../../components/forms/form";
import { InputField } from "../../../components/forms/fields/InputField";
import { BooleanField } from "../../../components/forms/fields/BooleanField";
import { FormActions } from "../../../components/forms/FormActions";
import { observer } from "mobx-react";
import { AddressField } from "ts/components/forms/fields/AddressField";
import { TextAreaField } from "ts/components/forms/fields/TextAreaField";
import { ContactField } from "../../../components/forms/fields/ContactField";
import { toJS, action, set } from "mobx";
import { SelectField } from "../../../components/forms/fields/SelectField";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import { default as RootStore } from "../../../stores/root-store";
import { CustomerEdit } from "./CustomerEdit";
import { FormErrors } from "../../../components/forms/FormErrors";
import emailSpellChecker from '@zootools/email-spell-checker';
import { isEmpty } from "lodash-es";


export interface CompanyEditInformation {
  orgId: string;
  name: string;
  line1?: string;
  line2?: string;
  zip?: string;
  city?: string;
}

interface Props {
  type?: "private" | "company";
  companyInformation?: CompanyEditInformation
  id?: number
}
type P = Props;

const validationRules: IValidationRules<CustomerEditModel> = {
  InvoiceAddress: [(address) => address == null || (!address.PostalCode || !address.PostalCode.trim()) ? "Du må oppgi et postnummer" : null]
}

@observer
export class CustomerEditForm extends React.Component<P> {
  store: FormStore<CustomerEditModel>;

  constructor(props: P) {
    super(props);
    const companyInfo = this.props.companyInformation;
    this.store = new FormStore<CustomerEditModel>(validationRules, {
      Name: companyInfo?.name,
      OrganizationId: companyInfo?.orgId,
      InvoiceAddress: {
        City: companyInfo?.city,
        PostalCode: companyInfo?.zip,
        Country: "no",
        Line1: companyInfo?.line1,
        Line2: companyInfo?.line2
      },
      DiscountPercentage: 0,
      InvoiceDueDays: 15,
      CombinedZinkInvoice: true
    }, (field, value) => {
      if (field === "Email" || field === "InvoiceEmail" || field === "NotificationEmail")
        this.validateEmail(field, value);
    });

    if (this.props.id)
      this.store.loadModel("customers", this.props.id);
  }

  @action
  copyAddress = (source: "InvoiceAddress" | "PickupAddress", target: "DeliveryAddress" | "PickupAddress") => {
    var currentInvoiceAddress = toJS(this.store.values[source]);

    if (currentInvoiceAddress == null)
      return;

    this.store.values[target] = { CustomerId: 0, LocationIsVerified: false };

    var targetAddress = this.store.values[target];
    targetAddress!.Name = this.store.values.Name;
    targetAddress!.Line1 = currentInvoiceAddress?.Line1;
    targetAddress!.Line2 = currentInvoiceAddress?.Line2;
    targetAddress!.PostalCode = currentInvoiceAddress?.PostalCode;
    targetAddress!.City = currentInvoiceAddress?.City;
    targetAddress!.Country = currentInvoiceAddress?.Country;
  }

  validateEmail = (field: string, value: string) => {
    const r = {} as any;
    const suggestedEmail = emailSpellChecker.run({
      email: value,
      topLevelDomains: ['com', 'org', 'no', 'se'], // replaces existing TLDs
    });
    if (suggestedEmail) {
      r[field + "_suggestion"] = `Mener du ${suggestedEmail.full}?`;
      this.setState(old => ({ ...old, ...r }));
    } else {
      r[field + "_suggestion"] = null;
      this.setState(old => ({ ...old, ...r }));
    }
  }

  render() {
    const { companyInformation, type, id } = this.props;
    const isCustomer = RootStore.UserStore.isCustomer;
    const isAdmin = RootStore.UserStore.isAdministrator;

    if (id && !this.store.modelHasLoaded)
      return null;

    let header = "Kunde ";
    if (id != null) {
      header += ` ${toSerialNumber(EntityType.Customer, id)}`;
      if (type === "company" && companyInformation)
        header += - `${companyInformation!.name} (${companyInformation!.orgId})`;
    }

    return (
      <div className="panel form-container">
        <Form
          store={this.store}
          name="Kunde"
          entityType={EntityType.Customer}
          redirectToList={false}
          className="edit-form edit-form-padding">
          <h3>{header}</h3>
          <InputField<CustomerEditModel>
            field="Name"
            type="text"
            label="Navn"
            autoFocus
            showIf={_ => !isCustomer}
            helpText="Her spesifiserer du navnet på kunden. Merk at bokføringsforskriften krever korrekt faktura-navn for mva-fradrag." />
          <InputField<CustomerEditModel>
            field="OrganizationId"
            type="text"
            label="Organisasjonsnummer"
            showIf={value => isAdmin && !isEmpty(value.OrganizationId)}
            helpText="Her kan du endre organisasjonsnummeret til kunden." />
          <InputField<CustomerEditModel>
            field="Email"
            type="email"
            inputMode="email"
            label="E-post"
            innerContent={<span className="suggestion-span">{(this.state as any)?.["Email_suggestion"]}</span>}
            helpText="Her kan du skrive inn epost-adressen til kunden." />
          <h4>Faktura</h4>
          <BooleanField<CustomerEditModel>
            field="RequiresInvoiceReference"
            label="Krever fakturareferanse"
            helpText="Indikerer om kunden krever prosjektreferanse på faktura." />
          {isAdmin && <>
            <BooleanField<CustomerEditModel>
              field="CombinedZinkInvoice"
              label="Samlet varmforsinking"
              helpText="Indikerer de forskjellige varmforsinkingsartiklene skal kombineres til én rad på kundens faktura." />
            <SelectField<CustomerEditModel>
              field="InvoiceDueDays"
              label="Antall forfallsdager"
              helpText="Antall dager kunden har på å betale sendt faktura." >
              <option value="0">0 dager</option>
              <option value="7">7 dager</option>
              <option value="10">10 dager</option>
              <option value="14">2 uker</option>
              <option value="15">15 dager</option>
              <option value="20">20 dager</option>
              <option value="30">30 dager</option>
              <option value="45">45 dager</option>
              <option value="60">60 dager</option>
              <option value="90">90 dager</option>
              <option value="120">120 dager</option>
            </SelectField>
            <BooleanField<CustomerEditModel>
              field="ExcludeFromStatistics"
              label="Ekskluder fra statistikk"
              helpText="Indikerer om kunden skal ekskluderes fra statistikk, f.eks intern- eller testkunder." />
            <InputField<CustomerEditModel>
              field="DiscountPercentage"
              label="Prisavslag i prosent"
              type="number"
              postfix="%"
              helpText="Antall prosent kunden får på varmforsinkingspriser." />
          </>}
          <InputField<CustomerEditModel>
            field="InvoiceEmail"
            type="email"
            inputMode="email"
            label="Fakturaepost"
            innerContent={<span className="suggestion-span">{(this.state as any)?.["InvoiceEmail_suggestion"]}</span>}
            helpText="Epostadresse som faktura sendes til." />
          <AddressField<CustomerEditModel>
            field="InvoiceAddress"
            label="Fakturaadresse"
            showInformation={false}
            showPicker={false}
            showContactFields={false}
            showNameField={false}
            helpText="Postnummer og poststed er påkrevet. Merk; benyttes kun dersom kunden ikke støtter EHF eller har epostadresse for faktura.">
          </AddressField>
          {!isCustomer && <TextAreaField<CustomerEditModel>
            field="InvoiceTerms"
            label="Betingelser"
            helpText="Fakturabetingelser og informasjon"
          />}
          <h4>Informasjon</h4>
          <TextAreaField<CustomerEditModel>
            field="PublicNote"
            label="Informasjon og behandling"
            helpText={isCustomer ? "Kundeinformasjon og behandlingsinformasjon" : "Kundeinformasjon og behandlingsinformasjon - Synlig for kunde."}
          />
          {!isCustomer && <TextAreaField<CustomerEditModel>
            field="PrivateNote"
            label="Interninformasjon"
            helpText="Intern informasjon om kunden og behandling"
          />}
          <InputField<CustomerEditModel>
            field="NotificationEmail"
            inputMode="email"
            type="email"
            label="Varsel-epost"
            innerContent={<span className="suggestion-span">{(this.state as any)?.["NotificationEmail_suggestion"]}</span>}
            helpText="Epost som mottar varsler dersom det ikke er spesifisert noen kontakter"
          />
          <InputField<CustomerEditModel>
            field="WebUrl"
            inputMode="url"
            type="text"
            label="Webadresse"
            helpText="Webadresse til hjemmesiden for firmaet"
          />
          <InputField<CustomerEditModel>
            field="FacebookUrl"
            inputMode="url"
            type="text"
            label="Facebook"
            helpText="Webadresse til facebooksiden for firmaet"
          />
          <h4>Transport</h4>
          <AddressField<CustomerEditModel>
            field="PickupAddress"
            label="Henteadresse"
            helpText="Standard adresse for henting av varer. Du kan oppgi ytterligere adresser under adresse-valget i hovedmenyen."
            showPicker={false}
            showContactFields={false}
            showNameField={true}>
            <div><span className="small-action-button" onClick={e => this.copyAddress("InvoiceAddress", "PickupAddress")}>Kopier fakturaadresse</span></div>
          </AddressField>
          <AddressField<CustomerEditModel>
            field="DeliveryAddress"
            label="Leveringsadresse"
            helpText="Standard adresse for levering av varer. Du kan oppgi ytterligere adresser under adresse-valget i hovedmenyen."
            showPicker={false}
            showContactFields={false}
            showNameField={true}>
            <div><span className="small-action-button" onClick={e => this.copyAddress("InvoiceAddress", "DeliveryAddress")}>Kopier fakturaadresse</span>&nbsp;<span className="small-action-button" onClick={e => this.copyAddress("PickupAddress", "DeliveryAddress")}>Kopier Henteadresse</span></div>
          </AddressField>
          <FormErrors type="Kunden" />
          <FormActions />
        </Form>
      </div>
    )
  }
}