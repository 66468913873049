import * as React from "react";
import { DataStore } from "ts/stores/data-store";
import { AuditModel } from "ts/models/shared/AuditModel";
import { observer } from "mobx-react";
import { since, toDateWithHM } from "ts/core/dateHelpers";
import "./audit.tsx.scss";

interface Props {
  type?: string;
  id?: number | null;
  isWorkorderRelated?: boolean;
  userId?: number;
  customerId?: number;
}

@observer
export class Audit extends React.Component<Props> {
  _store: DataStore<Array<AuditModel>>;

  constructor(props: Props) {
    super(props);
    const { type, id, isWorkorderRelated, userId, customerId } = this.props;

    let url = "";

    if (isWorkorderRelated)
      url = `/api/audit/workorder/${id}`;
    else if (type != null && id != null)
      url = `/api/audit/forEntity/${type}/${id}`;
    else if (userId != null)
      url = `/api/audit/forUser/${userId}`;
    else if (customerId != null)
      url = `/api/audit/forCustomer/${customerId}`;

    const changes = type != null ? [{ type: type, id: id }] : [];
    this._store = new DataStore<Array<AuditModel>>(url, changes);
  }

  componentWillUnmount() {
    this._store.dispose();
  }

  render() {
    const data = this._store.data || [];

    if (data.length == 0)
      return <p>Ingen historikk tilgjenglig.</p>;

    return <div className="table-container audit-table"><table>
      <thead>
        <tr>
          <th>Type</th>
          <th>Id</th>
          <th>Dato</th>
          <th>Handling</th>
          <th>
            <div className="diff-row">
              <span>Operasjon</span>
              <span>Felt</span>
              <span>Endring</span>
            </div>
          </th>
          <th>Av</th>
        </tr>
      </thead>
      <tbody>
        {data.map(d => <tr key={d.Id}>
          <td>{mapTypeToName(d.Type)}</td>
          <td>{d.EntityId}</td>
          <td className="no-wrap"><span title={since(d.Created)}>{toDateWithHM(d.Created)}</span></td>
          <td>{mapChangeToName(d.Change)}</td>
          <td><PresentDiff diff={d.Diff} /></td>
          <td>{d.UserName}</td>
        </tr>)}
      </tbody>
    </table></div>
  }
}

// <td><JSONPretty json={d.Diff || {}} /></td>

const PresentDiff: React.FC<{ diff: Array<any> | undefined }> = ({ diff }) => {

  if (diff == null)
    return null;

  return <div>
      {diff.map((operation: any, index: number) => <div key={index} className="diff-row"><span>{operation.op}</span><span>{operation.path}</span><span>{JSON.stringify(operation.value)}</span></div>)}
  </div>;
}


function mapChangeToName(change: string) {
  if (change == "Added")
    return "Ny";
  if (change == "Modified")
    return "Endring";
  return change;
}

function mapTypeToName(type: string) {
  if (type == "Logi.Proflyt.Data.Entities.UnitWeightEntity")
    return "Kollivekt";
  if (type == "Logi.Proflyt.Data.Entities.InvoiceEntity")
    return "Fakturagrunnlag";
  if (type == "Logi.Proflyt.Data.Entities.UnitStateEntity")
    return "Kollistatus";
  if (type == "Logi.Proflyt.Data.Entities.UnitEntity")
    return "Kolli";
  if (type == "Logi.Proflyt.Data.Entities.WorkOrderEntity")
    return "Arbeidsordre";
  if (type == "Logi.Proflyt.Data.Entities.OrderEntity")
    return "Bestilling";
  if (type == "Logi.Proflyt.Data.Entities.CustomerEntity")
    return "Kunde";
  if (type == "Logi.Proflyt.Data.Entities.ComplaintEntity")
    return "Reklamasjon";
  if (type == "Logi.Proflyt.Data.Entities.AddressEntity")
    return "Adresse"
  if (type == "Logi.Proflyt.Data.OfferEntity")
    return "Tilbud";
  return type;
}