import * as React from "react";
import * as moment from "moment";
import { Network } from "../../../../api/network";
import { BreadCrumb } from "../../../../components/shared/BreadCrumb";
import { UnitState } from "../../../../models/shared/UnitState";
import { Pending } from "../../../../components/shared/Pending";
import { DayNavigator } from "./DayNavigator";
import { DayStateView } from "./DayStateView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareMinus, faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import "./DayView.tsx.scss";
import { NotificationHub } from "../../../../core/signalR";
import { uniqBy } from "lodash-es";
import { useOnEntityChange } from "../../../../core/hooks/useOnEntityChange";


export const DayView = () => {
  const [toggle, setToggle] = React.useState(true);
  //const [day, setDay] = React.useState<Date>(new Date(2022,5,20));  
  const [day, setDay] = React.useState<Date>(moment().startOf('day').toDate());  
  const [data, setData] = React.useState<Array<any> | null>(null);

  const loadData = async () => {
    const network = new Network();
    const result = await network.get(`/api/units/UnitsByState/${moment(day).format("YYYY-MM-DD")}`);
    if (result) {
      setData(result as any);
    }
  }

  React.useEffect(() => {
    setData(null);
    loadData();
  }, [day]);

  useOnEntityChange("WorkOrderEntity", loadData);

  const states = new Map<UnitState, string>();
  states.set(UnitState.Received, "Motatt");
  states.set(UnitState.Checked, "Kontrollert");
  states.set(UnitState.Preparing, "Henges opp");
  states.set(UnitState.Prepared, "Hengt opp");
  states.set(UnitState.Packaging, "Pakkes");
  states.set(UnitState.Packaged, "Pakket");
  states.set(UnitState.Ready, "Utveid");
  states.set(UnitState.Delivered, "Hentet");
  states.set(UnitState.External, "Ekstern behandling");
  states.set(UnitState.Stop, "Stopp");
  states.set(UnitState.Paint, "Lakkeres");
  states.set(UnitState.Sandblast, "Sandblåsing");
  states.set(UnitState.Canceled, "Kanselert");

  return <div className="page-workorders-days">
    <h1>Dagsoversikt</h1>
    <BreadCrumb
      workSpaceType="list"
      singular="Dagsoversikt"
      plural="Arbeidsordre"
      additionalLinks={[{ title: "Dagsoversikt", path: "" }]}
      routeBasePath="workorders" />
    <div className="panel">
      <div className="day-view-header">
        <DayNavigator day={day} onChange={setDay} />
        <FontAwesomeIcon icon={!toggle ? faSquarePlus : faSquareMinus} onClick={e => setToggle(!toggle)} className="icon" />
        </div>
      {data == null && <div className="flex-center"><Pending /></div>}
      {data && [...states].map(s => <div key={s[0]}>
        <DayStateView title={s[1]} data={uniqBy(data.filter(d => d.State === s[0]), l => l.UnitId)} toggle={toggle} />
      </div>)}
    </div>
  </div>
}