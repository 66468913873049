import * as React from "react";
import { Form } from "../../../components/forms/form";
import { TextAreaField } from "../../../components/forms/fields/TextAreaField";
import { OrderEditModel } from "../../../models/shared/OrderEditModel";
import { InputField } from "../../../components/forms/fields/InputField";
import { DateField } from "../../../components/forms/fields/DateField";
import { EnumField } from "../../../components/forms/fields/EnumField";
import { IValidationRules, FormStore } from "../../../stores/form-store";
import { observer } from "mobx-react";
import { Enums } from "ts/stores/lookup-store";
import { GalvanizingStandard } from "ts/models/shared/GalvanizingStandard";
import { PaintType } from "ts/models/shared/PaintType";
import { BooleanField } from "ts/components/forms/fields/BooleanField";
import { AddressField } from "ts/components/forms/fields/AddressField";
import { FormActions } from "ts/components/forms/FormActions";
import { FormErrors } from "ts/components/forms/FormErrors";
import { CustomerField } from "ts/components/forms/fields/CustomerField";
import { RalColorField, isValidRalColor } from "ts/components/forms/fields/RalColorField";
import { AddressType } from "ts/models/shared/AddressType";
import { computed, observable } from "mobx";
import { ContactsField } from "../../../components/forms/fields/ContactsField";
import { stringToDate, addWorkDays, formatDate, toISO8601Date } from "../../../core/dateHelpers";
import { SelectField } from "../../../components/forms/fields/SelectField";
import { UnitsForTransportField } from "../../../components/forms/fields/UnitsForTransportField";
import { showRalAndGloss } from "../../../core/util";
import { default as RootStore } from "../../../stores/root-store";
import { CorrosivityCategory } from "../../../models/shared/CorrosivityCategory";
import { Network } from "../../../api/network";
import { DescriptionLookupModel } from "../../../models/shared/DescriptionLookupModel";
import { Pending } from "../../../components/shared/Pending";
import { FilesField } from "../../../components/forms/fields/FilesField";
import { TypeAheadField } from "../../../components/forms/fields/TypeaheadField";
import { EntityType } from "../../../core/stringHelpers";

interface Props {
  id?: number
}

@observer
export class OrderEditForm extends React.Component<Props> {
  store: FormStore<OrderEditModel>;

  @computed get selectedCustomerId() {
    return this.store.values.CustomerId;
  }

  @observable offers?: Array<DescriptionLookupModel>;

  @computed get selectedEstimatedDeliveryDate() {
    const setDateString = this.store.values.EstimatedDeliveryDate;
    if (setDateString)
      return new Date(Date.parse(setDateString));
    else
      return new Date();
  }

  constructor(props: any) {
    super(props);

    const validationRules: IValidationRules<OrderEditModel> = {
      "CustomerId": [(value) => value ? null : "Du må oppgi en kunde for å opprette en bestilling"],
      "RalCode": [(value, data) => !showRalAndGloss(data.Paint) || isValidRalColor(value) ? null : `${value} er ikke en gyldig ral kode.`],
      "Description": [(value, data) => data.IsCertificate === true && (value == null || value.length === 0) ? "Du må oppgi en beskrivelse av godset når du ønsker samsvarserklæring." : null]
    };

    const isCustomer = RootStore.UserStore.isCustomer;
    const customerId = RootStore.UserStore.customerId;
    const network = new Network();

    this.store = new FormStore<OrderEditModel>(validationRules, {
      Standard: GalvanizingStandard.ISO1461,
      Corrosivity: CorrosivityCategory.C3,
      CustomerId: isCustomer ? customerId : undefined,
      Paint: PaintType.None,
      Contacts: [{ UserId: RootStore.UserStore.userId!, IsDefault: false }]
    }, async (field, value, values) => {

      // Automatically set completion date to estimated delivery date + 5 workdays
      if (field == "EstimatedDeliveryDate" && value != null && values.RequestedCompletionDate == null) {
        var startDate = stringToDate(value);
        const completionDate = await network.getSimple<string>(`/api/Lookup/AddWorkDays/5/${toISO8601Date(startDate)}`)
        values.RequestedCompletionDate = formatDate(completionDate, "YYYY-MM-DD");
        this.store.setServerError("RequestedCompletionDate", undefined);
      }

      if (field == "EstimatedDeliveryDate" || field == "RequestedCompletionDate")
        values.IsRushRequest = await isRushRequest(values);

      if (field == "CustomerId") {
        values.OfferId = undefined;
        this.updateOffers(values.CustomerId!);
      }
    });

    if (this.props.id) {
      this.store.loadModel("orders", this.props.id).then(_ => {
        if (this.store.values.CustomerId)
          this.updateOffers(this.store.values.CustomerId);
      });
    }

    if (isCustomer)
      this.updateOffers(customerId!);
  }

  async updateOffers(customerId: number) {
    const network = new Network();
    this.offers = [];
    const offers = await network.get<Array<DescriptionLookupModel>>(`/api/offers/availableOffersForCustomer/${customerId}`);
    this.offers = offers || [];
  }

  render() {

    if (this.store.isEdit && !this.store.modelHasLoaded)
      return <Pending />;

    const isCustomer = RootStore.UserStore.isCustomer;

    return (
      <Form
        store={this.store}
        name="Bestilling"
        entityType={EntityType.Order}
        redirectToList={true}
        customerId={this.store.values.CustomerId}
        className="edit-form edit-form-padding">
        <h4>Bestilling</h4>
        <CustomerField<OrderEditModel>
          field="CustomerId"
          label="Kunde"
          helpText="Spesifiser kunde for denne bestillingen."
          showIf={(value) => !isCustomer && value.Id == null}
        />
        <TextAreaField<OrderEditModel>
          field="Description"
          label="Godsbeskrivelse"
          helpText="Skriv litt informasjon om hva som skal varmforsinkes." />
        <FilesField<OrderEditModel>
          field="Documents"
          label="Filer"
          helpText="Her kan du legge ved dokumenter og bilder som beskriver godset i bestillingen."
        />
        <h4>Frakt</h4>
        <BooleanField<OrderEditModel>
          field="RequiresPickup"
          label="Til RåZink"
          options={["RåZink henter varene", "Jeg (kunde) leverer varene selv"]}
        />
        <AddressField<OrderEditModel>
          field="PickupAddress"
          label="Henteadresse"
          selectedCustomerId={this.selectedCustomerId}
          addressType={AddressType.Pickup}
          showNameField={false}
          showContactFields={true}
          showIf={(values, customerId, value) => values.RequiresPickup === true}
        />
        <BooleanField<OrderEditModel>
          field="RequiresDelivery"
          label="Fra RåZink"
          options={["RåZink frakter varene til leveransested", "Jeg (kunde) henter varene selv"]}
        />
        <AddressField<OrderEditModel>
          field="DeliveryAddress"
          selectedCustomerId={this.store.values == null ? undefined : this.store.values.CustomerId}
          addressType={AddressType.Delivery}
          showNameField={true}
          showContactFields={true}
          label="Leveringsadresse"
          showIf={values => values.RequiresDelivery === true}
        />
        <UnitsForTransportField<OrderEditModel>
          field="Units"
          showIf={(values, customerId, value) => values.RequiresPickup || values.RequiresDelivery || false}
        />
        <h4>Detaljer</h4>
        <InputField<OrderEditModel>
          field="EstimatedWeight"
          label="Cirka vekt"
          helpText="Fyll inn omtrent hvor mye som kommer til å bli levert i vekt."
          showIf={(values, customerId, value) => !(values.RequiresPickup || values.RequiresDelivery || false)}
          postfix="kg"
          inputMode="numeric"
          type="number" />
        <InputField<OrderEditModel>
          field="UnitCount"
          label="Antall kolli"
          helpText="Fyll inn antall kolli som vil bli levert."
          postfix="stk"
          showIf={(values, customerId, value) => !(values.RequiresPickup || values.RequiresDelivery || false)}
          inputMode="numeric"
          type="number" />
        <TypeAheadField<OrderEditModel>
          field="InvoiceReference"
          label="Fakturareferanse"
          helpText="Dersom du ønsker fakturaen skal ha en referanse, fyller du inn den her. Merk; leses ikke, bruk informasjonsfeltet under for annen info." />
        <DateField<OrderEditModel>
          field="EstimatedDeliveryDate"
          label="Mottaksdato"
          helpText="Her fyller du inn dato for når du tror varene blir levert RåZink / skal hentes av RåZink."
          time={false}
          numberOfMonths={2}
          filterWorkDays
          minDate={new Date()}
        />
        <DateField<OrderEditModel>
          field="RequestedCompletionDate"
          label="Ønsket ferdigstillelsesdato"
          helpText="Her fyller du inn seneste dato for når varene må være ferdig."
          time={false}
          numberOfMonths={2}
          filterWorkDays
          minDate={this.selectedEstimatedDeliveryDate}
          additionalInfo={values => {
            if (values.IsRushRequest)
              return <p className="warning">Ønsket ferdigstillelsedato er mindre enn fem virkedager fra mottaksdato.
                Hurtiglevering medfører ekstrakostnader og er avhengig av kapasitet i anlegget.
                Du vil motta en epost med informasjon om ønsket dato er mulig.
              </p>
            return null;
          }}
        />
        <TextAreaField<OrderEditModel>
          field="RushRequestReason"
          label="Hastebegrunnelse"
          helpText="Her kan du fylle inn litt informasjon dersom det er spesielle årsaker til hvorfor du ønsker lavere enn fem virkedagers leveringstid."
          showIf={values => values.IsRushRequest ?? false}
        />
        <BooleanField<OrderEditModel>
          field="CustomerAcceptsOvertime"
          label="Godtar overtidsbruk"
          helpText="Kryss av dersom du aksepterer bruk av overtid for å nå ønsket leveringstid. Vil medføre ekstrakostnader. Se prisliste for oppdaterte priser."
          showIf={values => values.IsRushRequest ?? false}
        />
        <EnumField<OrderEditModel>
          field="Corrosivity"
          sort={false}
          enumType={Enums.CorrosivityCategory}
          label="Korrosjonsklasse"
          required={true}
          helpText="Velg korrosjonsklasse i henhold til dine krav. Hvis du er usikker, benytt standard C3."
        />
        <EnumField<OrderEditModel>
          field="Paint"
          sort={false}
          enumType={Enums.PaintType}
          label="Lakkering"
          required={true}
          helpText="Spesifiser om varen skal lakkeres etter varmforsinking."
        />
        <RalColorField<OrderEditModel>
          field="RalCode"
          label="RAL kode"
          showIf={(values) => values.Paint == PaintType.Powder || values.Paint == PaintType.Wet}
          helpText="Her spesifiserer du fargekode hvis du ønsker våt eller pulverlakkering." />
        <SelectField<OrderEditModel>
          field="PaintGloss"
          label="Glans"
          showIf={(values) => values.Paint == 1 || values.Paint == 2}
          helpText="Her spesifiserer glans for våt eller pulverlakkering.">
          <option value="0">Ingen glans</option>
          <option value="30">30 - Silkematt</option>
          <option value="40">40</option>
          <option value="50">50 - Halvblank</option>
          <option value="60">60</option>
          <option value="70">70 - Blank</option>
          <option value="80">80</option>
          <option value="90">90 - Høyblank</option>
        </SelectField>
        <ContactsField<OrderEditModel>
          field="Contacts"
          label="Kontakter"
          showIf={(row) => row.CustomerId != null}
          helpText="Her kan du spesifisere hvilke kontaktpersoner som skal kontaktes for denne bestillingen." />
        <h4>Dokumentasjon</h4>
        <BooleanField<OrderEditModel>
          field="IsCertificate"
          label="Samsvarserklæring"
          helpText="En samsvarserklæring (tidl. sertifikat) bekrefter at varmforsinkingen har foregått i henhold til ISO 1461:2022. Medfører ekstrakostnad." />
        <BooleanField<OrderEditModel> field="IsMeasurements" label="Måleprotokoll" helpText="En måleprotokoll detaljerer tykkelsen på sinkbelegget på godset som er varmforsinket. Medfører ekstrakostnad." />
        <EnumField<OrderEditModel>
          field="NumberOfParts"
          label="Antall deler"
          helpText="Omtrent antall deler som skal varmforsinkes. Påvirker antall målinger som tas i måleprotokoll."
          enumType={Enums.MeasurementNumberOfParts}
          required={false}
          initialText="Vet ikke"
          sort={false}
          showIf={(values) => values.IsMeasurements === true}
        />
        <SelectField<OrderEditModel>
          field="AboveTwoSquareMeters"
          label="Overflate"
          showIf={(values) => values.IsMeasurements === true}
          helpText="Omtrentlig overflateareal på delene som varmforsinkes. Er delene forskjellig, benytt areal på den største delen. Pårvirker antall målinger som tas i måleprotokoll. ">
          <option value="0">Vet ikke</option>
          <option value="1">2 m² eller mindre</option>
          <option value="2">Over 2 m²</option>
        </SelectField>
        <h4>Annet</h4>
        <TextAreaField<OrderEditModel>
          field="Information"
          helpText="Eventuell ytterligere informasjon."
          label="Informasjon" />
        {this.offers != null && this.offers.length > 0 && <SelectField<OrderEditModel>
          field="OfferId"
          label="Tilbud"
          helpText="Dersom denne bestillingen er en del av en inngått avtale kan du velge den her.">
          <option value="0">-- Velg tilbud eller avtale --</option>
          {this.offers.map(o => <option key={o.Id} value={o.Id}>{o.Name}</option>)}
        </SelectField>}
        <FormErrors type="Bestillingen" />
        <FormActions />
      </Form>)
  }
}

const isRushRequest = async (values: Partial<OrderEditModel>) => {
  if (values.EstimatedDeliveryDate == null || values.RequestedCompletionDate == null)
    return false;

  const recievedDate = stringToDate(values.EstimatedDeliveryDate);
  const requestDate = stringToDate(values.RequestedCompletionDate);

  const network = new Network();
  const standardDeliveryDate = stringToDate(await network.getSimple<string>(`/api/Lookup/AddWorkDays/5/${toISO8601Date(recievedDate)}`));

  return requestDate < standardDeliveryDate;
}