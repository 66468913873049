import * as React from "react";
import { PropsFromWrapperToField, Wrap } from "../wrapField";
import { SelectFieldInternal } from "./SelectField";
import { default as RootStore } from "../../../stores/root-store";
import { Enums } from "../../../stores/lookup-store";
import { BaseLookupModel } from "../../../models/shared/BaseLookupModel";

interface OwnProps {
  enumType: Enums;
  sort: boolean;
  required: boolean;
  initialText?: string
}

interface State {
  options?: Array<BaseLookupModel>;
}

type P = PropsFromWrapperToField<number | string> & OwnProps;

class EnumField extends React.PureComponent<P, State> {
  state: State = { options: undefined };

  async componentDidMount() {
    const { enumType, sort } = this.props;
    const options = await RootStore.LookupStore.getEnums(enumType, sort == null ? true : sort);
    this.setState({ options });
  }

  render() {
    const options = this.state.options;
    const { value, required, initialText } = this.props;
    if (options == undefined)
      return null;

    const hasValue = value != null && options.some(o => o.Id == value);
    const text = initialText ?? "-- Velg et alternativ --";

    return <SelectFieldInternal {...this.props}>
      {(!hasValue || !required) && <option value="">{required ? text : initialText ?? ""}</option>}
      {options.map(o => <option key={o.Id} value={o.Id}>{o.Name}</option>)}
    </SelectFieldInternal>
  }
}

const wrapped = Wrap<P, number | string>(EnumField);

export {
  wrapped as EnumField
}