import * as React from "react";
import { WorkOrderDetailsUnitModel } from "../../../models/shared/WorkOrderDetailsUnitModel";
import { observer } from "mobx-react";
import { StateName, NumberWithPostfix, Indicator, stateToName } from "../../../components/shared/display";
import { Button } from "../../../components/shared/Button";
import EditIcon from "svg/edit.svg";
import WeightIcon from "svg/weight.svg";
import MoveIcon from "svg/move.svg";
import DeleteIcon from "svg/trash.svg";
import { UnitState } from "../../../models/shared/UnitState";
import { GenericDataStore } from "../../../stores/genericData-store";
import { WorkOrderDetailsModel } from "../../../models/shared/WorkOrderDetailsModel";
import { DetailValueField } from "../../../components/details/DetailValueField";
import { add, getImageUrl, isMobile, setEventDragData } from "../../../core/util";
import { Dialog } from "../../../core/dialog";
import { default as RootStore } from "../../../stores/root-store";
import { MoveUnitModelState, MoveUnitDialogComponent } from "./MoveUnitDialog";
import { WeightDialog } from "../units/WeightDialog";
import { HookUnitDialogComponent } from "./HookDialog";
import { checkBeforeStateChange, isWeightPossiblyInvalid } from "./workOrderHelpers";
import { ArticleLookupModel } from "../../../models/shared/ArticleLookupModel";
import { Lookup } from "../../../stores/lookup-store";
import { observable } from "mobx";
import { Direction } from "../../../models/shared/Direction";
import { toSerialNumber, EntityType } from "../../../core/stringHelpers";
import classNames from "classnames";
import { ImageStack } from "./units/ImageStack";
import "./unitListItem.scss";
import { ImageSize } from "../../../models/shared/ImageSize";
import { UnitImagesDialog } from "./UnitImagesDialog";
import { toDateWithHMShort } from "../../../core/dateHelpers";
import { IonIcon } from "@ionic/react";
import { pricetagOutline, printOutline } from "ionicons/icons";
import { Network } from "../../../api/network";
import { infoToast } from "../../../components/shared/Toasts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";


interface Props {
  unit: WorkOrderDetailsUnitModel
  store: GenericDataStore<WorkOrderDetailsModel>
  isSelected: boolean;
};

@observer
export class UnitListItem extends React.Component<Props> {
  @observable articles: Array<ArticleLookupModel>;

  constructor(props: Props) {
    super(props);
    this.articles = RootStore.LookupStore.getList<ArticleLookupModel>(Lookup.Articles);
  }

  changeIncomingStateOptions = () => [UnitState.Received, UnitState.Checked, UnitState.Preparing, UnitState.Prepared, UnitState.Stop, UnitState.Canceled, UnitState.Sandblast, UnitState.External]
    .filter(s => s != this.props.unit.State)
    .map(s => ({
      text: stateToName(s),
      onClick: () => {
        checkBeforeStateChange(
          this.props.store.entity!,
          s,
          (isReady: boolean) => this.props.store.changeUnitState(this.props.unit.Id, s, isReady),
          this.props.unit
        );
      }
    }));

  changeOutgoingStateOptions = () => [UnitState.Packaging, UnitState.Packaged, UnitState.Ready, UnitState.Delivered, UnitState.Paint, UnitState.External]
    .filter(s => s != this.props.unit.State)
    .map(s => ({
      text: stateToName(s),
      onClick: () => {
        checkBeforeStateChange(
          this.props.store.entity!,
          s,
          (isReady: boolean) => this.props.store.changeUnitState(this.props.unit.Id, s, isReady),
          this.props.unit
        );
      }
    }));

  editUnit = () => {
    const history = RootStore.RouterStore.history;
    const { Id, WorkOrderId } = this.props.unit;
    history.push(`/workorders/${WorkOrderId}/units/${Id}/edit`);
  }

  move = () => {
    const { Id, WorkOrderId } = this.props.unit;

    const dialog = new Dialog<MoveUnitModelState>();

    dialog.title = "Flytte kolli";
    dialog.closable = true;
    dialog.buttons = [
      {
        text: "Flytt",
        isEnabled: (state) => (state.useExsting && state.workOrderId && state.workOrderId > 0) || !state.useExsting,
        onClick: async (state, onClose) => {
          await this.props.store.moveUnit(Id, state.useExsting ? state.workOrderId : undefined);
          onClose();
        }
      }
    ];
    dialog.content = MoveUnitDialogComponent;

    RootStore.UIStore.addDialog(dialog);
  }

  manageWeights = () => {

    const dialog = new Dialog<any>();
    dialog.title = "Vekter";
    dialog.closable = true;
    dialog.content = <WeightDialog store={this.props.store} unitId={this.props.unit.Id} />
    dialog.buttons = [{
      onClick: (state, fnClose) => fnClose(),
      text: "Lukk"
    }];

    RootStore.UIStore.addDialog(dialog);
  }

  hookUnit = () => {
    const dialog = new Dialog<any>();
    dialog.title = "Oppheng";
    dialog.closable = true;
    dialog.content = <HookUnitDialogComponent unitId={this.props.unit.Id} />
    dialog.buttons = [{
      onClick: (state, fnClose) => fnClose(),
      text: "Lukk"
    }];
    RootStore.UIStore.addDialog(dialog);
  }

  delete = () => {
    const { Id, WorkOrderId } = this.props.unit;

    const dialog = Dialog.OkCancelDialog(
      "Slette kolli",
      `Er du sikker på at du ønsker å slette kolli ${Id} på arbeidsordre ${WorkOrderId}?`,
      "Slett",
      async close => {
        await this.props.store.deleteUnit(Id);
        close();
      }
    );
    RootStore.UIStore.addDialog(dialog);
  }

  printLabel = async (unitId: number) => {
    const network = new Network();
    await network.getSimple("/api/units/print?unitIds=" + unitId);
    infoToast("Merklapp har blitt sendt til skriver");
  }

  render() {
    const { unit, isSelected } = this.props;
    const { isCustomer } = RootStore.UserStore;

    const weight = unit.ProductionWeights!.map(w => w.Weight).reduce(add, 0);
    const isValidWeight = !isWeightPossiblyInvalid(unit.IncomingWeight, weight);

    if (unit.Direction == Direction.Incoming) {
      return (
        <li className={classNames("unitlist-item", { "unitlist-item--selected": isSelected })} id={`unit_${unit.Id}`}>
          <div>
            <ImageStack unit={unit} store={this.props.store} />
            <ErrorImageRow unit={unit} store={this.props.store} />
          </div>
          <div className="details-panel-item">
            <h4>Informasjon</h4>
            <DetailValueField title="Id">{toSerialNumber(EntityType.Unit, unit.Id)}</DetailValueField>
            <DetailValueField title="Status" ><StateName state={unit.State} /></DetailValueField>
            {!isCustomer && <DetailValueField title="Bommer">
              {unit.Booms && unit.Booms.length > 0 &&
                <table className="text-table"><tbody>
                  {unit.Booms.map((b, i) => <tr key={i}><td className="no-wrap">Bom {b.Id} fra {b.FromWing} til {b.ToWing}</td></tr>)}
                </tbody></table>
              }</DetailValueField>}
            <DetailValueField title="Manglet pall"><Indicator value={unit.MissingPallet} /></DetailValueField>
            <h4>Statushistorikk</h4>
            <table className="state-history"><tbody>
              {unit.States!.map(s => <tr key={s.Created}><td><StateName state={s.State} /></td><td>{toDateWithHMShort(s.Created)}</td></tr>)}
            </tbody></table>
          </div>
          <div className="details-panel-item">
            <h4>Mål og vekt</h4>
            <DetailValueField right title="Svart vekt"><NumberWithPostfix number={unit.IncomingWeight} post="kg" /></DetailValueField>
            {unit.PalletWeight > 0 && !isCustomer && <DetailValueField right title="Vekt pall"><NumberWithPostfix number={unit.PalletWeight} post="kg" /></DetailValueField>}
            <DetailValueField right title="Lengde"><NumberWithPostfix number={unit.Length} post="cm" /></DetailValueField>
            <DetailValueField right title="Bredde"><NumberWithPostfix number={unit.Width} post="cm" /></DetailValueField>
            <DetailValueField right title="Høyde"><NumberWithPostfix number={unit.Height} post="cm" /></DetailValueField>
            {unit.AvailableArticles && <DetailValueField title="Innhold" wrap long>
              <ul>{unit.AvailableArticles.map(article =>
                <li key={article}>{this.articles.find(a => a.Id == article)?.Name}</li>)}
              </ul></DetailValueField>}
          </div>
          <div className="details-panel-item">
            <h4>Kontroll</h4>
            <DetailValueField title="Opphengshull"><Indicator value={unit.MissingAttachmentHoles} /></DetailValueField>
            <DetailValueField title="Dreneringshull"><Indicator value={unit.MissingDrainageHoles} /></DetailValueField>
            <DetailValueField title="Syrelomme"><Indicator value={unit.AcidPocket} /></DetailValueField>
            <DetailValueField title="Dobbeldupp"><Indicator value={unit.DoubleDip} /></DetailValueField>
            <DetailValueField title="Fjerne maling"><Indicator value={unit.RemovePaint} /></DetailValueField>
            <DetailValueField title="Sinkavbrenning"><Indicator value={unit.ContainsZink} /></DetailValueField>
            <DetailValueField title="Avfetting"><Indicator value={unit.Degrease} /></DetailValueField>
            <DetailValueField title="Konstruksjonsfeil"><Indicator value={unit.ConstructionError} /></DetailValueField>
            <DetailValueField title="Rehabilitering"><Indicator value={unit.Refurbish} /></DetailValueField>
            <DetailValueField title="Skadet"><Indicator value={unit.Damaged} /></DetailValueField>
            <DetailValueField title="Må sandblåses"><Indicator value={unit.Sandblast} /></DetailValueField>
            <DetailValueField title="Annet"><Indicator value={unit.Other} /></DetailValueField>
          </div>
          {!isCustomer && <div className="details-panel-item details-action-list details-right">
            <Button text={stateToName(unit.State)} options={this.changeIncomingStateOptions()} className={getStateColorClass(unit.State, unit.StateChange)}></Button>
            <Button text="Rediger" onClick={() => this.editUnit()} icon={EditIcon}></Button>
            {!isCustomer && <Button text="Merkelapp" title="Skriv ut merkelapp" onClick={() => this.printLabel(unit.Id)} icon={<FontAwesomeIcon icon={faPrint} />}></Button>}
            <Button text="Flytt" onClick={() => this.move()} icon={MoveIcon}></Button>
            <Button text="Slett" onClick={() => this.delete()} icon={DeleteIcon}></Button>
          </div>}
        </li>
      );
    } else {
      return (
        <li className={classNames("unitlist-item", { "unitlist-item--selected": isSelected })} id={`unit_${unit.Id}`}>
          <ImageStack unit={unit} store={this.props.store} />
          <div className="details-panel-item">
            <h4>Informasjon</h4>
            <DetailValueField title="Id">{toSerialNumber(EntityType.Unit, unit.Id)}</DetailValueField>
            <DetailValueField title="Status" ><StateName state={unit.State} /></DetailValueField>
            <h4>Statushistorikk</h4>
            <table className="state-history"><tbody>
              {unit.States!.map(s => <tr key={s.Created}><td><StateName state={s.State} /></td><td>{toDateWithHMShort(s.Created)}</td></tr>)}
            </tbody></table>
          </div>
          <div className="details-panel-item">
            <h4>Mål og vekt</h4>
            <DetailValueField right title="Blank vekt"><NumberWithPostfix number={weight} post="kg" className={isValidWeight ? "" : "error"} /></DetailValueField>
            {unit.PalletWeight > 0 && !isCustomer && <DetailValueField right title="Vekt pall"><NumberWithPostfix number={unit.PalletWeight} post="kg" /></DetailValueField>}
            <DetailValueField right title="Lengde"><NumberWithPostfix number={unit.Length} post="cm" /></DetailValueField>
            <DetailValueField right title="Bredde"><NumberWithPostfix number={unit.Width} post="cm" /></DetailValueField>
            <DetailValueField right title="Høyde"><NumberWithPostfix number={unit.Height} post="cm" /></DetailValueField>
          </div>
          {!isCustomer && <div className="details-panel-item details-action-list details-right">
            <Button text={stateToName(unit.State)} options={this.changeOutgoingStateOptions()} className={getStateColorClass(unit.State, unit.StateChange)}></Button>
            <Button text="Rediger" onClick={() => this.editUnit()} icon={EditIcon}></Button>
            <Button text="Vekter" onClick={() => this.manageWeights()} icon={WeightIcon}></Button>
            {!isCustomer && <Button text="Merkelapp" title="Skriv ut merkelapp" onClick={() => this.printLabel(unit.Id)} icon={<FontAwesomeIcon icon={faPrint} />}></Button>}
            <Button text="Flytt" onClick={() => this.move()} icon={MoveIcon}></Button>
            <Button text="Slett" onClick={() => this.delete()} icon={DeleteIcon}></Button>
          </div>}
        </li>);
    }
  }
}

function getStateColorClass(state: UnitState, change: string) {
  return `state-bg-color-${state}`;
}


const ErrorImageRow: React.FC<{ unit: WorkOrderDetailsUnitModel, store: GenericDataStore<WorkOrderDetailsModel> }> = ({ unit, store }) => {

  if (unit?.ErrorImages == null || unit.ErrorImages.length == 0)
    return null;

  const onImageSelect = (id: string) => {
    if (isMobile()) {
      window.dispatchEvent(new CustomEvent("onErrorImageSelect", { detail: { unit, id } }));
    } else {
      const dialog = Dialog.OkDialog("Bilder", <UnitImagesDialog unitId={unit.Id} selected={id} store={store} />);
      dialog.fullScreen = true;
      RootStore.UIStore.addDialog(dialog);
    }
  };

  return <ul className="error-image-row">
    {unit.ErrorImages.map(i => <li key={i.Id}>
      <div role="img" style={{ backgroundImage: `url("${getImageUrl(i, ImageSize.Preview)}")` }} aria-label={i.Name} onClick={e => onImageSelect(i.Id)} onDragStart={e => setEventDragData(e, i)} draggable />
    </li>)}
  </ul>
}
