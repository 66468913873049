import classNames from "classnames";
import * as React from "react";

export class SmallButton extends React.PureComponent<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> {
  render() {
    return <button
      className={classNames("button", "button-small", this.props.className)}
      type="button"
      {...this.props}
    >{this.props.children}
    </button>
  }
}