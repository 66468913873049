import { UnitState } from "../../../models/shared/UnitState";
import { WorkOrderDetailsModel } from "../../../models/shared/WorkOrderDetailsModel";
import { Dialog } from "../../../core/dialog";
import { WorkOrderDetailsUnitModel } from "../../../models/shared/WorkOrderDetailsUnitModel";
import { isNullOrWhitespace } from "../../../core/stringHelpers";
import { showRalAndGloss, getCorrosivityName, paintDescription } from "../../../core/util";
import { CorrosivityCategory } from "../../../models/shared/CorrosivityCategory";
import { Direction } from "../../../models/shared/Direction";
import * as React from "react";
import { WorkOrderState } from "../../../models/shared/WorkOrderState";
import { PaintType } from "../../../models/shared/PaintType";

export function checkBeforeStateChange(wo: WorkOrderDetailsModel, state: UnitState, successAction: (isReady: boolean) => void, unit?: WorkOrderDetailsUnitModel) {

  const dialogs: Array<Dialog<any>> = [];
  let isReady = false;

  /**
   * Lukker dialogboksen og viser neste. Hvis siste, gjennomfører endringen
   */
  const onOK = (onClose: () => void, _isReady: boolean = false) => {
    onClose();
    isReady = isReady || _isReady;
    dialogs.shift();
    if (dialogs.length == 0)
      successAction(isReady);
    else
      Dialog.Show(dialogs[0]);
  }

  if (state == UnitState.Preparing && showRalAndGloss(wo.PaintType)) {
    const dialog = Dialog.OkDialogWithMessage(
      "Lakkering",
      "Denne arbeidsorden skal lakkeres, sørg for at GUL merkelapp følger ordren i anlegget.",
      onOK
    );
    dialogs.push(dialog);
  }

  if (state == UnitState.Ready) {
    const result = isWeightOK(wo, unit);
    if (!result[0]) {
      const dialog = Dialog.OkCancelDialog(
        "Mulig avvik på vekt",
        `Vekten på utveide kolli (${result[2]} kg) er enten lavere eller over seks prosent av innveid (${result[1]} kg) vekt.`,
        "Oppdater status",
        onOK
      );
      dialogs.push(dialog);
    }
  }

  if (state == UnitState.Ready && wo.IsCEMarking) {
    const dialog = Dialog.OkDialogWithMessage(
      "CE Markering",
      "Husk at denne arbeidsorden skal CE-merkes.",
      onOK
    );
    dialogs.push(dialog);
  }

  if (state == UnitState.Ready && wo.RequiresInvoiceReference && isNullOrWhitespace(wo.InvoiceText)) {
    dialogs.push(
      Dialog.OkCancelDialog(
        "Mangler kundereferanse",
        `Denne arbeidsordren mangler kundereferanse. Dette er påkrevet for ${wo.CustomerName}. `,
        "Sett ferdig alikevel",
        onOK
      )
    );
  }

  if (state == UnitState.Preparing && wo.Corrosivity != CorrosivityCategory.C3) {
    const dialog = Dialog.OkDialogWithMessage(
      "Korrosjonsklasse",
      `Denne arbeidsorden har korrosjonsklasse ${getCorrosivityName(wo.Corrosivity)} som kan medføre spesiell håndtering i produksjonen.`,
      onOK
    );
    dialogs.push(dialog);
  }

  if (state >= UnitState.Packaging && wo.IsMeasurements && !wo.HasMeasurements) {
    const dialog = Dialog.OkDialogWithMessage(
      "Måleprotkoll",
      `Kunden har bedt om måleprotokoll for denne ordren. Sørg for at korrekt antall målinger tas før arbeidsordren ferdigstilles.`,
      onOK
    );
    dialogs.push(dialog);
  }

  // Vil dette fullføre arbeidsordre
  const completingStates = [UnitState.Canceled, UnitState.External, UnitState.Prepared, UnitState.Delivered, UnitState.Ready]
  const allIncomingDone = wo.Units.filter(u => u.Direction == Direction.Incoming).every(i => completingStates.includes(i.State));
  const allOtherOutgointReady = wo.Units.filter(u => u.Id != unit?.Id).filter(u => u.Direction == Direction.Outgoing).every(i => completingStates.includes(i.State));
  const needed = wo.Aggregate?.IsReady == false && wo.ComputedState < WorkOrderState.Ready;
  const tilPulverlakk = wo.PaintType == PaintType.Powder || wo.PaintType == PaintType.Wet;

  if (needed && !tilPulverlakk && unit != null && (state == UnitState.Ready || state == UnitState.Delivered) && allIncomingDone && allOtherOutgointReady) {

    const dialog = new Dialog<{}>();
    dialog.title = "Ferdigstille";
    dialog.content = React.createElement("p", null, "Alle utgående kolli blir nå satt ferdig. Skal arbeidsordren også ferdigstilles. Kunden vil da motta ferdigmelding. Hvis det er fler kolli som skal produseres velg fortsett i produksjon.");
    dialog.closable = false;
    dialog.buttons = [
      { onClick: (_, fnClose) => { onOK(fnClose, true); }, text: "Ferdigstill arbeidsordre" },
      { onClick: (_, fnClose) => { onOK(fnClose, false); }, text: "Fortsett i produksjon" },
      { onClick: (_, fnClose) => fnClose(), text: "Avbryt" }];
    dialogs.push(dialog);
  }

  if (dialogs.length > 0)
    Dialog.Show(dialogs[0]);
  else
    successAction(false);
}


function isWeightOK(wo: WorkOrderDetailsModel, unit?: WorkOrderDetailsUnitModel) {

  const willCompleteJob = unit == null ||
    wo.Units
      .filter(u => u.Id != unit.Id)
      .every(u => u.State == UnitState.Ready || u.State == UnitState.Delivered);

  if (willCompleteJob) {
    const initial = wo.Units.map(u => u.IncomingWeight).reduce((a, b) => a + b, 0);
    const production = wo.Units.map(u =>
      (u.ProductionWeights?.map(p => p.Weight) || []).reduce((a, b) => a + b, 0)
    ).reduce((a, b) => a + b, 0);

    return [!isWeightPossiblyInvalid(initial, production), initial, production];
  }

  return [true];
}

/** Checks if the production weight > 6% of less than 5%*/
export function isWeightPossiblyInvalid(initial: number, production?: number) {
  if (initial == 0 || production == null || production == 0)
    return false;

  const diff = 1.0 / initial * production;
  if (diff > 1.06 || diff < 0.95)
    return true;

  return false;
}