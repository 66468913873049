import { UIStore } from "./ui-store";
import { UserStore } from "./user-store";
import { RouterStore } from "mobx-react-router";
import { IListDefinition } from "../components/lists/IListDefinition";
import { ListStore } from "./list-store";
import { BaseModel } from "../models/shared/BaseModel";
import { LookupStore } from "./lookup-store";
import { NotificationHub } from "ts/core/signalR";

export interface IRootStore {
  UIStore: UIStore;
  UserStore: UserStore;
  RouterStore: RouterStore;
  LookupStore: LookupStore;
  ListStores: Map<string, ListStore<BaseModel & any>>;
  getListStore<T extends BaseModel>(config: IListDefinition<T>): ListStore<T>;
}

class RootStore implements IRootStore {

  public UIStore: UIStore;
  public UserStore: UserStore;
  public RouterStore: RouterStore;
  public LookupStore: LookupStore;
  public ListStores: Map<string, ListStore<BaseModel & any>>;

  constructor() {
    this.UIStore = new UIStore();
    this.UserStore = new UserStore();
    this.RouterStore = new RouterStore();
    this.LookupStore = new LookupStore();
    this.ListStores = new Map();
  }

  public getListStore<T extends BaseModel>(config: IListDefinition<T>) {
    if (!this.ListStores.has(config.id))
      this.ListStores.set(config.id, new ListStore(config));
    return this.ListStores.get(config.id) as ListStore<T>;
  }

  public async initializeStores() {
    await this.UIStore.initialize(this);
    await this.UserStore.initialize(this);
    await this.LookupStore.initialize(this);
  }

}

const root = new RootStore();
root.initializeStores();
NotificationHub.initialize(root.UserStore);

export default root;
