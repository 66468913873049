import * as React from 'react';
import { observer, inject } from "mobx-react";
import { Dialog, DialogContentType } from '../../core/dialog';
import { observable } from 'mobx';
import "./dialog.scss";
import { default as RootStore } from "../../stores/root-store";
import * as ReactDOM from 'react-dom';
import classNames from 'classnames';

@observer
export class DialogListComponent extends React.Component {

  private bodyCssClass = "has-dialog";
  private scrollYPosition = 0;

  render() {
    const dialogs = RootStore.UIStore.getDialogs;
    if (dialogs == null || dialogs.length == 0)
      return null;

    if (this.scrollYPosition == 0)
      this.scrollYPosition = window.scrollY;

    return (
      <>
        <DialogOverlay />
        {dialogs.map((d, i) => <DialogComponent
          key={d.id}
          {...d}
          onClose={() => RootStore.UIStore.closeDialog(d.id)}
          onTop={i === dialogs.length - 1}
        />)}
      </>)
  }

  componentDidUpdate(props: any) {

    if (this.scrollYPosition > 0)
      window.scrollTo(window.scrollX, this.scrollYPosition);

    if (RootStore.UIStore.hasDialog) {
      if (!document.body.classList.contains(this.bodyCssClass))
        document.body.classList.add(this.bodyCssClass);
    }

    if (!RootStore.UIStore.hasDialog) {
      if (document.body.classList.contains(this.bodyCssClass))
        document.body.classList.remove(this.bodyCssClass);

      if (this.scrollYPosition > 0) {
        window.scrollTo(window.scrollX, this.scrollYPosition);

        this.scrollYPosition = 0;
      }
    }
  }
}

const DialogOverlay = () => ReactDOM.createPortal(<div className="dialog-overlay" tabIndex={-1} />, document.body);

type P = Dialog<any> & { onClose: () => void; onTop: boolean };

@observer
class DialogComponent extends React.Component<P> {

  @observable contentState: any = {};
  dialogRef: React.RefObject<HTMLDivElement>;
  focusedElBeforeOpen: HTMLElement;
  focusElements: Array<HTMLElement> = [];

  constructor(props: P) {
    super(props);

    document.addEventListener("keydown", this.closeOnEscape);

    this.dialogRef = React.createRef<HTMLDivElement>();
    this.focusedElBeforeOpen = document.activeElement as HTMLElement;

  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeOnEscape);
    if (this.focusedElBeforeOpen)
      this.focusedElBeforeOpen.focus();

  }


  closeOnEscape = (ev: KeyboardEvent) => {
    const { closable, onClose, onTop } = this.props;
    if (closable && onTop && ev.key === "Escape")
      onClose();

    // Make sure focues doesn't leave the dialog
    if (ev.key === "Tab") {
      const lastFocusElement = this.focusElements[this.focusElements.length - 1];
      if (ev.shiftKey) {
        if (document.activeElement == this.focusElements[0]) {
          ev.preventDefault()
          lastFocusElement.focus();
        }
      } else {
        if (document.activeElement == lastFocusElement) {
          ev.preventDefault();
          this.focusElements[0].focus();
        }
      }
    }

  }

  componentDidMount() {
    const dialogElement = this.dialogRef.current;
    if (dialogElement) {
      // set focus on first element in dialog box
      this.focusElements = Array.from(dialogElement.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'));
      if (this.focusElements.length > 0)
        this.focusElements[0].focus();
    }

  }

  render() {
    const { closable, id, title, onClose, content, buttons, onTop, fullScreen } = this.props;
    return (
      <div
        id={id}
        role="dialog"
        aria-modal="true"
        aria-labelledby={`${id}_title`}
        className={classNames("rl-dialog", { "dialog-fullscreen": fullScreen })}
        ref={this.dialogRef}>
        <div className="header">
          <h3 id={`${id}_title`}>{title}</h3>
          {closable && onTop && <button type="button" aria-label="Lukk" onClick={() => onClose()}>X</button>}
        </div>
        <div className="content container-has-padding">
          <DialogContent content={content} contentState={this.contentState} onCloseFn={onClose} />
        </div>
        <div className="footer">
          {buttons.map((btn, index) => <button
            key={index}
            type="button"
            className="dialog-button"
            onClick={e => btn.onClick(this.contentState, onClose)}
            disabled={btn.isEnabled != null && btn.isEnabled(this.contentState) === false}>{btn.text}</button>
          )}
        </div>
      </div>
    )
  }
}

interface DialogContentProps {
  content?: DialogContentType<any>;
  contentState: any;
  onCloseFn: () => void;
}

class DialogContent extends React.Component<DialogContentProps> {
  render() {

    const { contentState, content, onCloseFn } = this.props;

    if (content == null)
      return null;

    if (React.isValidElement(content)) {
      // Plain html elements have type string and does not have a onCloseFn property
      // If not assume content is a class component that implements onCloseFn.
      if (typeof content.type === "string")
        return React.cloneElement(content);
      else
        return React.cloneElement(content, { onCloseFn });
    }
    else if (React.Component.isPrototypeOf(content) || React.PureComponent.isPrototypeOf(content))
      return React.createElement(content, { dialogState: contentState, onCloseFn });
    else if (typeof content === "function")
      return (content as any)(contentState, onCloseFn) as React.ReactNode;
    else
      return content;
  }
}
